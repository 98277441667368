import { Link } from 'react-router-dom'

import styled from 'styled-components'

const CustomLink = styled(Link)`
  display: ${({ display }) => display};
  width: ${({ width }) => width};
  padding: ${({ padding }) => (padding ? padding : '9px 16px')};
  background: ${({ disabled, simple, background }) =>
    simple ? 'none' : disabled ? '#D6D9DF' : background ? `var(--${background})` : 'var(--primary-2)'};


  text-align: ${({ align }) => (align ? align : 'center')};
  color: ${({ color }) => (color ? `var(--${color})` : 'var(--primary-1)')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  margin-left: ${({ marginleft }) => marginleft};

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  border: ${({ disabled, simple, background }) =>
    `1px solid ${simple ? '#fff' : background ? `var(--${background})` : disabled ? '#D6D9DF' : 'var(--primary-2)'}`};

  &:hover {
    color: var(--primary-1);
    border: var(--hover-btns);
    text-decoration: none;
  }
`

const BaseLink = ({
  text,
  to,
  width,
  display,
  background,
  disabled,
  simple,
  padding,
  align,
  color,
  marginLeft
}) => {
  return (
    <CustomLink
      to={to}
      disabled={disabled}
      simple={simple?.toString()}
      color={color}
      padding={padding}
      width={width}
      display={display}
      background={background}
      align={align}
      marginleft={marginLeft}
    >
      {text}
    </CustomLink>
  )
}

export default BaseLink
