export default class Routes {
  static PARAM_SPLITTER = /:\w+/g

  static dashboard = {
    index: '/dashboard'
  }

  static roles = {
    index: '/roles',
    affiliate: {
      index: '/roles/affiliate',
      create: '/roles/affiliate/create',
      edit: '/roles/affiliate/edit/:uuid'
    },
    advertiser: {
      index: '/roles/advertiser',
      create: '/roles/advertiser/create',
      edit: '/roles/advertiser/edit/:uuid'
    },
    manager: {
      index: '/roles/manager',
      create: '/roles/manager/create',
      edit: '/roles/manager/edit/:uuid'
    }
  }

  static coldApi = {
    index: '/cold-api',
    reColdApi: '/cold-api/re-cold-api',
    leadList: '/cold-api/lead-list'
  }

  static offer = {
    index: '/offer',
    direct: {
      create: '/offer/direct/create',
      edit: '/offer/direct/edit/:uuid',
      detail: '/offer/direct/detail/:uuid'
    },
    api: {
      create: '/offer/api/create',
      edit: '/offer/api/edit/:uuid',
      detail: '/offer/api/detail/:uuid'
    }
  }

  static statistic = {
    index: '/cold-api',
    postbacks: '/statistic/postbacks',
    direct: {
      general: '/statistic/direct-link/general',
      clicks: '/statistic/direct-link/clicks',
      conversion: '/statistic/direct-link/conversion',
    },
    api: {
      general: '/statistic/api/general',
      clicks: '/statistic/api/clicks',
      userSession: '/statistic/api/user-session',
      conversion: '/statistic/api/conversion',
    }
  }

  static getUrl(path, params = {}) {
    const dynamicKeys = path.match(Routes.PARAM_SPLITTER) || []

    const missingKeys = dynamicKeys.filter(
      (key) => !Object.keys(params).includes(key.slice(1))
    )

    if (missingKeys.length > 0) {
      console.warn(
        `Missing parameters for URL generation: ${missingKeys.map((key) => key.slice(1)).join(', ')}`
      )

      return '/'
    }

    return dynamicKeys.reduce(
      (url, key) => url.replace(key, params[key.slice(1)] || key),
      path
    )
  }
}

/*
// Корректный случай
const editUrl = Routes.getUrl(Routes.offer.direct.edit, { uuid: '12345' });
console.log(editUrl); // "/offer/direct/edit/12345"

// Ошибка: отсутствуют параметры
const invalidUrl = Routes.getUrl(Routes.offer.api.edit);
console.log(invalidUrl); // "/error" + предупреждение в консоль
*/
