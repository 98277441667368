// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --white: #fff;
  --bright: #ced2d8;

  --blue: #0186a4;
  --red: #bb4126;
  --green: #00a98a;
  --orange: #ff9900;

  --primary-1: #2f455c;
  --primary-2: #34f5c5;
  --primary-3: #2f455c;
  --primary-4: #0e9cc3;
  --primary-5: #1fcbf2;

  --secondary-1: #ededea;
  --secondary-2: #5f7994;
  --secondary-3: #97a2ac;

  --table-lvl-2: #E8EFFC;
  --table-lvl-3: #DEE7F8;
  --table-lvl-4: #D9E2F3;
  --table-lvl-5: #D4DDED;
  --table-lvl-6: #CED8EA;
  --table-lvl-7: #C5D1E3;

  --status-success: #00A98A;
  --status-warning: #DA9227;
  --status-error: #BB4126;
  --status-critical: #711500;
  --status-inactive: #6F6F6F;

  --square-1: #34f5c5;
  --square-2: #1fc8f5;
  --square-3: #005f79;
  --square-4: #003645;


  --hover-btns: 1px solid var(--primary-2);
  --cubic: 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
`, "",{"version":3,"sources":["webpack://./src/styles/vars.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;;EAEjB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,iBAAiB;;EAEjB,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;;EAEpB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;;EAEtB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;;EAEtB,yBAAyB;EACzB,yBAAyB;EACzB,uBAAuB;EACvB,0BAA0B;EAC1B,0BAA0B;;EAE1B,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;;;EAGnB,wCAAwC;EACxC,2CAA2C;AAC7C","sourcesContent":[":root {\n  --white: #fff;\n  --bright: #ced2d8;\n\n  --blue: #0186a4;\n  --red: #bb4126;\n  --green: #00a98a;\n  --orange: #ff9900;\n\n  --primary-1: #2f455c;\n  --primary-2: #34f5c5;\n  --primary-3: #2f455c;\n  --primary-4: #0e9cc3;\n  --primary-5: #1fcbf2;\n\n  --secondary-1: #ededea;\n  --secondary-2: #5f7994;\n  --secondary-3: #97a2ac;\n\n  --table-lvl-2: #E8EFFC;\n  --table-lvl-3: #DEE7F8;\n  --table-lvl-4: #D9E2F3;\n  --table-lvl-5: #D4DDED;\n  --table-lvl-6: #CED8EA;\n  --table-lvl-7: #C5D1E3;\n\n  --status-success: #00A98A;\n  --status-warning: #DA9227;\n  --status-error: #BB4126;\n  --status-critical: #711500;\n  --status-inactive: #6F6F6F;\n\n  --square-1: #34f5c5;\n  --square-2: #1fc8f5;\n  --square-3: #005f79;\n  --square-4: #003645;\n\n\n  --hover-btns: 1px solid var(--primary-2);\n  --cubic: 250ms cubic-bezier(0.4, 0, 0.2, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
