import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const CrumbsList = styled.ul`
  display: flex;
  column-gap: 10px;
  width: fit-content;
  min-height: 16px;
  margin-left: 84px;

  // Скрытие и отключение событий для первого уровня

  &.first-level {
    visibility: hidden;
    pointer-events: none;
  }
`

const CustomLink = styled(NavLink)`
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-2);

  &:hover,
  &:focus {
    color: var(--secondary-2);
    text-decoration: underline;
  }
`

const capitalize = (str) => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function isUUID(str) {
  const uuidRegex = /^[a-zA-Z0-9]{18,24}$/
  return uuidRegex.test(str)
}

const Breadcrumbs = ({
  containerClasses = '',
  listClasses = '',
  activeClasses = ''
}) => {
  const location = useLocation()
  const paths = location.pathname
  if (!paths) return null

  const pathSegments = paths.split('/').filter(Boolean)

  // Проверка на первую вложенность
  const isFirstLevel = pathSegments.length <= 1

  const displaySegments = pathSegments.filter((segment) => {
    const isUuid = isUUID(segment)
    return !isUuid
  })

  return (
    <CrumbsList
      className={`${containerClasses} ${isFirstLevel ? 'first-level' : ''}`}
      aria-label="Breadcrumb navigation"
    >
      {displaySegments.map((segment, index) => {
        const isLast = index === displaySegments.length - 1

        const href = isLast
          ? `/${pathSegments.join('/')}`
          : `/${pathSegments.slice(0, index + 1).join('/')}`

        const isActive = paths === href
        const itemClasses = `${listClasses} ${isActive ? activeClasses : ''}`
        const itemLink = capitalize(segment)

        return (
          <React.Fragment key={index}>
            <li className={itemClasses}>
              <CustomLink to={href} end={isLast}>
                {itemLink}
              </CustomLink>
            </li>
            {!isLast && <span> / </span>}
          </React.Fragment>
        )
      })}
    </CrumbsList>
  )
}

export default Breadcrumbs
